import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Seo title="Datenschutz"></Seo>
      <section className="container">
        <div className="section-title">
          <h2>Datenschutz</h2>
        </div>
        <div className="text-justify-last-left font-body-1">
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen.
              Datenschutz hat einen besonders hohen Stellenwert für die
              Geschäftsleitung unseres Unternehmens. Eine Nutzung unserer
              Internetseiten ist grundsätzlich ohne jede Angabe
              personenbezogener Daten möglich. Sofern eine betroffene Person
              besondere Services unseres Unternehmens (z.B. Kontaktformular)
              über unsere Internetseite in Anspruch nehmen möchte, kann jedoch
              eine Verarbeitung personenbezogener Daten erforderlich werden. Ist
              die Verarbeitung personenbezogener Daten erforderlich und besteht
              für eine solche Verarbeitung keine gesetzliche Grundlage, holen
              wir generell eine Einwilligung der betroffenen Person ein.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Die Verarbeitung personenbezogener Daten, beispielsweise des
              Namens, der Anschrift, E-Mail-Adresse oder Telefonnummer einer
              betroffenen Person, erfolgt stets im Einklang mit der
              Datenschutz-Grundverordnung (DSGVO) und in Übereinstimmung mit den
              für unser Unternehmen geltenden landesspezifischen
              Datenschutzbestimmungen. Mittels dieser Datenschutzerklärung
              möchte unser Unternehmen die Öffentlichkeit über Art, Umfang und
              Zweck der von uns erhobenen, genutzten und verarbeiteten
              personenbezogenen Daten informieren. Ferner werden betroffene
              Personen mittels dieser Datenschutzerklärung über die ihnen
              zustehenden Rechte aufgeklärt.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Unser Unternehmen hat als für die Verarbeitung Verantwortlicher
              zahlreiche technische und organisatorische Maßnahmen umgesetzt, um
              einen möglichst lückenlosen Schutz der über diese Internetseite
              verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch
              können internetbasierte Datenübertragungen grundsätzlich
              Sicherheitslücken aufweisen, sodass ein absoluter Schutz nicht
              gewährleistet werden kann. Aus diesem Grund steht es jeder
              betroffenen Person frei, personenbezogene Daten auch auf
              alternativen Wegen, beispielsweise telefonisch, an uns zu
              übermitteln. &nbsp;&nbsp;
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">1. Begriffsbestimmungen</b>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Unsere Datenschutzerklärung beruht auf den Begrifflichkeiten, die
              durch den Europäischen Richtlinien- und Verordnungsgeber beim
              Erlass der Datenschutz-Grundverordnung (DSGVO) verwendet wurden.
              Unsere Datenschutzerklärung soll sowohl für die Öffentlichkeit als
              auch für unsere Kunden und Geschäftspartner einfach lesbar und
              verständlich sein. Um dies zu gewährleisten, möchten wir vorab die
              verwendeten Begrifflichkeiten erläutern.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Wir verwenden in dieser Datenschutzerklärung unter anderem die
              folgenden Begriffe: <br />
            </span>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              <br />
            </b>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">a) personenbezogene Daten</span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Personenbezogene Daten sind alle Informationen, die sich auf eine
              identifizierte oder identifizierbare natürliche Person (im
              Folgenden „betroffene Person“) beziehen. Als identifizierbar wird
              eine natürliche Person angesehen, die direkt oder indirekt,
              insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
              zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
              oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der
              physischen, physiologischen, genetischen, psychischen,
              wirtschaftlichen, kulturellen oder sozialen Identität dieser
              natürlichen Person sind, identifiziert werden kann.
            </span>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              <br />
            </b>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">b) betroffene Person</span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Betroffene Person ist jede identifizierte oder identifizierbare
              natürliche Person, deren personenbezogene Daten, von dem für die
              Verarbeitung Verantwortlichen verarbeitet werden.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">c) Verarbeitung</span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Verarbeitung ist jeder mit oder ohne Hilfe automatisierter
              Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im
              Zusammenhang mit personenbezogenen Daten wie das Erheben, das
              Erfassen, die Organisation, das Ordnen, die Speicherung, die
              Anpassung oder Veränderung, das Auslesen, das Abfragen, die
              Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder
              eine andere Form der Bereitstellung, den Abgleich oder die
              Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">d) Einschränkung der Verarbeitung</span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Einschränkung der Verarbeitung ist die Markierung gespeicherter
              personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung
              einzuschränken.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">e) Profiling</span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Profiling ist jede Art der automatisierten Verarbeitung
              personenbezogener Daten, die darin besteht, dass diese
              personenbezogenen Daten verwendet werden, um bestimmte persönliche
              Aspekte, die sich auf eine natürliche Person beziehen, zu
              bewerten, insbesondere, um Aspekte bezüglich Arbeitsleistung,
              wirtschaftlicher Lage, Gesundheit, persönlicher Vorlieben,
              Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder
              Ortswechsel dieser natürlichen Person zu analysieren oder
              vorherzusagen.
            </span>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              <br />
            </b>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">f) Pseudonymisierung</span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Pseudonymisierung ist die Verarbeitung personenbezogener Daten in
              einer Weise, auf welche die personenbezogenen Daten ohne
              Hinzuziehung zusätzlicher Informationen nicht mehr einer
              spezifischen betroffenen Person zugeordnet werden können, sofern
              diese zusätzlichen Informationen gesondert aufbewahrt werden und
              technischen und organisatorischen Maßnahmen unterliegen, die
              gewährleisten, dass die personenbezogenen Daten nicht einer
              identifizierten oder identifizierbaren natürlichen Person
              zugewiesen werden.
            </span>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              <br />
            </b>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              g) Verantwortlicher oder für die Verarbeitung Verantwortlicher
            </span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Verantwortlicher oder für die Verarbeitung Verantwortlicher ist
              die natürliche oder juristische Person, Behörde, Einrichtung oder
              andere Stelle, die allein oder gemeinsam mit anderen über die
              Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
              entscheidet. Sind die Zwecke und Mittel dieser Verarbeitung durch
              das Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so
              kann der Verantwortliche beziehungsweise können die bestimmten
              Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der
              Mitgliedstaaten vorgesehen werden.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">h) Auftragsverarbeiter</span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Auftragsverarbeiter ist eine natürliche oder juristische Person,
              Behörde, Einrichtung oder andere Stelle, die personenbezogene
              Daten im Auftrag des Verantwortlichen verarbeitet.
            </span>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              <br />
            </b>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">i) Empfänger</span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Empfänger ist eine natürliche oder juristische Person, Behörde,
              Einrichtung oder andere Stelle, der personenbezogene Daten
              offengelegt werden, unabhängig davon, ob es sich bei ihr um einen
              Dritten handelt oder nicht. Behörden, die im Rahmen eines
              bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem
              Recht der Mitgliedstaaten möglicherweise personenbezogene Daten
              erhalten, gelten jedoch nicht als Empfänger.
            </span>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              <br />
            </b>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">j) Dritter</span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Dritter ist eine natürliche oder juristische Person, Behörde,
              Einrichtung oder andere Stelle außer der betroffenen Person, dem
              Verantwortlichen, dem Auftragsverarbeiter und den Personen, die
              unter der unmittelbaren Verantwortung des Verantwortlichen oder
              des Auftragsverarbeiters befugt sind, die personenbezogenen Daten
              zu verarbeiten.
            </span>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              <br />
            </b>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">k) Einwilligung</span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Einwilligung ist jede von der betroffenen Person freiwillig für
              den bestimmten Fall in informierter Weise und unmissverständlich
              abgegebene Willensbekundung in Form einer Erklärung oder einer
              sonstigen eindeutigen bestätigenden Handlung, mit der die
              betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung
              der sie betreffenden personenbezogenen Daten einverstanden ist.
              &nbsp;&nbsp;
            </span>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              <br />
            </b>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              2. Name und Anschrift des für die Verarbeitung Verantwortlichen
            </b>
          </div>
          <div>
            <span className="cf1 ff1">
              <b></b>
            </span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Verantwortlicher im Sinne der Datenschutz-Grundverordnung,
              sonstiger in den Mitgliedstaaten der Europäischen Union geltenden
              Datenschutzgesetze und anderer Bestimmungen mit
              datenschutzrechtlichem Charakter ist:
            </span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">Jautzke Augenoptik</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">Michael Jautzke</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">Rudower Str. 15 • 12351 Berlin</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">Telefon: 030 – 602 578 34</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              E-Mail: datenschutz@jautzke-augenoptik.de
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">Internet: www.jautzke-augenoptik.de</span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div></div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              3. Erfassung von allgemeinen Daten und Informationen
            </b>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Unsere Internetseite erfasst mit jedem Aufruf der Internetseite
              durch eine betroffene Person oder ein automatisiertes System eine
              Reihe von allgemeinen Daten und Informationen. Diese allgemeinen
              Daten und Informationen werden in den Logfiles des Servers
              gespeichert. Erfasst werden können die (1) verwendeten
              Browsertypen und Versionen, (2) das vom zugreifenden System
              verwendete Betriebssystem, (3) die Internetseite, von welcher ein
              zugreifendes System auf unsere Internetseite gelangt (sogenannte
              Referrer), (4) die Unterwebseiten, welche über ein zugreifendes
              System auf unserer Internetseite angesteuert werden, (5) das Datum
              und die Uhrzeit eines Zugriffs auf die Internetseite, (6) eine
              Internet-Protokoll-Adresse (IP-Adresse), (7) der
              Internet-Service-Provider des zugreifenden Systems und (8)
              sonstige ähnliche Daten und Informationen, die der Gefahrenabwehr
              im Falle von Angriffen auf unsere informationstechnologischen
              Systeme dienen.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Bei der Nutzung dieser allgemeinen Daten und Informationen ziehen
              wir keine Rückschlüsse auf die betroffene Person. Diese
              Informationen werden vielmehr benötigt, um (1) die Inhalte unserer
              Internetseite korrekt auszuliefern, (2) die Inhalte unserer
              Internetseite sowie die Werbung für diese zu optimieren, (3) die
              dauerhafte Funktionsfähigkeit unserer informationstechnologischen
              Systeme und der Technik unserer Internetseite zu gewährleisten
              sowie (4) um Strafverfolgungsbehörden im Falle eines
              Cyberangriffes die zur Strafverfolgung notwendigen Informationen
              bereitzustellen. Diese anonym erhobenen Daten und Informationen
              werden durch uns daher einerseits statistisch und ferner mit dem
              Ziel ausgewertet, den Datenschutz und die Datensicherheit in
              unserem Unternehmen zu erhöhen, um letztlich ein optimales
              Schutzniveau für die von uns verarbeiteten personenbezogenen Daten
              sicherzustellen. Die anonymen Daten der Server-Logfiles werden
              getrennt von allen durch eine betroffene Person angegebenen
              personenbezogenen Daten gespeichert. &nbsp;&nbsp;
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              <br />
            </b>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">4. Kontaktmöglichkeit über die Internetseite</b>
          </div>
          <div>
            <span className="cf1 ff1">
              <b></b>
            </span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Unsere Internetseite enthält aufgrund von gesetzlichen
              Vorschriften Angaben, die eine schnelle elektronische
              Kontaktaufnahme zu unserem Unternehmen sowie eine unmittelbare
              Kommunikation mit uns ermöglichen, was ebenfalls eine allgemeine
              Adresse der sogenannten elektronischen Post (E-Mail-Adresse)
              umfasst. Sofern eine betroffene Person per E-Mail oder über ein
              Kontaktformular den Kontakt, mit dem für die Verarbeitung
              Verantwortlichen aufnimmt, werden die von der betroffenen Person
              übermittelten personenbezogenen Daten automatisch gespeichert.
              Solche auf freiwilliger Basis von einer betroffenen Person an den
              für die Verarbeitung Verantwortlichen übermittelten
              personenbezogenen Daten werden für Zwecke der Bearbeitung oder der
              Kontaktaufnahme zur betroffenen Person gespeichert. Es erfolgt
              keine Weitergabe dieser personenbezogenen Daten an Dritte.
              &nbsp;&nbsp;
            </span>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              <br />
            </b>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              <b>5. </b>
              <b>routinemäßige Löschung und Sperrung von personenbezogenen </b>
              <b>Daten</b>
            </span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Der für die Verarbeitung Verantwortliche verarbeitet und speichert
              personenbezogene Daten der betroffenen Person nur für den
              Zeitraum, der zur Erreichung des Speicherungszwecks erforderlich
              ist oder sofern dies durch den Europäischen Richtlinien- und
              Verordnungsgeber oder einen anderen Gesetzgeber in Gesetzen oder
              Vorschriften, welchen der für die Verarbeitung Verantwortliche
              unterliegt, vorgesehen wurde. Entfällt der Speicherungszweck oder
              läuft eine vom Europäischen Richtlinien- und Verordnungsgeber oder
              einem anderen zuständigen Gesetzgeber vorgeschriebene
              Speicherfrist ab, werden die personenbezogenen Daten routinemäßig
              und entsprechend den gesetzlichen Vorschriften gesperrt oder
              gelöscht. &nbsp;&nbsp;
            </span>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              <br />
            </b>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">6. Rechte der betroffenen Person</b>
          </div>
          <div>
            <span className="cf1 ff1">
              <b></b>
            </span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">a) Recht auf Bestätigung</span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Jede betroffene Person hat das vom Europäischen Richtlinien- und
              Verordnungsgeber eingeräumte Recht, von dem für die Verarbeitung
              Verantwortlichen eine Bestätigung darüber zu verlangen, ob sie
              betreffende personenbezogene Daten verarbeitet werden. Möchte eine
              betroffene Person dieses Bestätigungsrecht in Anspruch nehmen,
              kann sie sich hierzu jederzeit an unseren Datenschutzbeauftragten
              oder einen anderen Mitarbeiter des für die Verarbeitung
              Verantwortlichen wenden.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">b) Recht auf Auskunft</span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Jede von der Verarbeitung personenbezogener Daten betroffene
              Person hat das vom Europäischen Richtlinien- und Verordnungsgeber
              gewährte Recht, jederzeit von dem für die Verarbeitung
              Verantwortlichen unentgeltliche Auskunft über die zu seiner Person
              gespeicherten personenbezogenen Daten und eine Kopie dieser
              Auskunft zu erhalten. Ferner hat der Europäische Richtlinien- und
              Verordnungsgeber der betroffenen Person Auskunft über folgende
              Informationen zugestanden:
            </span>
          </div>
          <div className="imTAJustify">
            <ul>
              <li>
                <span className="cf1">
                  <span className="fs12 ff1">die Verarbeitungszwecke</span>
                  <br />
                </span>
              </li>
            </ul>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <ul>
              <li>
                <span className="cf1">
                  <span className="fs12 ff1">
                    die Kategorien personenbezogener Daten, die verarbeitet
                    werden
                  </span>
                  <br />
                </span>
              </li>
            </ul>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <ul>
              <li>
                <span className="cf1">
                  <span className="fs12 ff1">
                    die Empfänger oder Kategorien von Empfängern, gegenüber
                    denen die personenbezogenen Daten offengelegt worden sind
                    oder noch offengelegt werden, insbesondere bei Empfängern in
                    Drittländern oder bei internationalen Organisationen
                  </span>
                  <br />
                </span>
              </li>
            </ul>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <ul>
              <li>
                <span className="cf1">
                  <span className="fs12 ff1">
                    falls möglich die geplante Dauer, für die die
                    personenbezogenen Daten gespeichert werden, oder, falls dies
                    nicht möglich ist, die Kriterien für die Festlegung dieser
                    Dauer
                  </span>
                  <br />
                </span>
              </li>
            </ul>
          </div>
          <div></div>
          <div className="imTAJustify">
            <br />
          </div>
          <div>
            <ul>
              <li>
                <span className="cf1">
                  <span className="fs12 ff1">
                    das Bestehen eines Rechts auf Berichtigung oder Löschung der
                    sie betreffenden personenbezogenen Daten oder auf
                    Einschränkung der Verarbeitung durch den Verantwortlichen
                    oder eines Widerspruchsrechts gegen diese Verarbeitung
                  </span>
                  <br />
                </span>
              </li>
            </ul>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <ul>
              <li>
                <span className="cf1">
                  <span className="fs12 ff1">
                    das Bestehen eines Beschwerderechts bei einer
                    Aufsichtsbehörde
                  </span>
                  <br />
                </span>
              </li>
            </ul>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <ul>
              <li>
                <span className="cf1">
                  <span className="fs12 ff1">
                    wenn die personenbezogenen Daten nicht bei der betroffenen
                    Person erhoben werden: Alle verfügbaren Informationen über
                    die Herkunft der Daten
                  </span>
                  <br />
                </span>
              </li>
            </ul>
          </div>
          <div></div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <ul>
              <li>
                <span className="cf1">
                  <span className="fs12 ff1">
                    das Bestehen einer automatisierten Entscheidungsfindung
                    einschließlich Profiling gemäß Artikel 22 Abs.1 und 4 DSGVO
                    und — zumindest in diesen Fällen — aussagekräftige
                    Informationen über die involvierte Logik sowie die Tragweite
                    und die angestrebten Auswirkungen einer derartigen
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Verarbeitung
                    für die betroffene Person
                  </span>
                  <br />
                </span>
              </li>
            </ul>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;&nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu,
              ob personenbezogene Daten an ein Drittland oder an eine
              internationale Organisation übermittelt wurden. Sofern dies der
              Fall ist, so steht der betroffenen Person im Übrigen das Recht zu,
              Auskunft über die geeigneten Garantien im Zusammenhang mit der
              Übermittlung zu erhalten. Möchte eine betroffene Person dieses
              Auskunftsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit
              an unseren Datenschutzbeauftragten oder einen anderen Mitarbeiter
              des für die Verarbeitung Verantwortlichen wenden.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">c) Recht auf Berichtigung</span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Jede von der Verarbeitung personenbezogener Daten betroffene
              Person hat das vom Europäischen Richtlinien- und Verordnungsgeber
              gewährte Recht, die unverzügliche Berichtigung sie betreffender
              unrichtiger personenbezogener Daten zu verlangen. Ferner steht der
              betroffenen Person das Recht zu, unter Berücksichtigung der Zwecke
              der Verarbeitung, die Vervollständigung unvollständiger
              personenbezogener Daten — auch mittels einer ergänzenden Erklärung
              — zu verlangen. Möchte eine betroffene Person dieses
              Berichtigungsrecht in Anspruch nehmen, kann sie sich hierzu
              jederzeit an unseren Datenschutzbeauftragten oder einen anderen
              Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
            </span>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              <br />
            </b>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              d) Recht auf Löschung (Recht auf Vergessen werden)
            </span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Jede von der Verarbeitung personenbezogener Daten betroffene
              Person hat das vom Europäischen Richtlinien- und Verordnungsgeber
              gewährte Recht, von dem Verantwortlichen zu verlangen, dass die
              sie betreffenden personenbezogenen Daten unverzüglich gelöscht
              werden, sofern einer der folgenden Gründe zutrifft und soweit die
              Verarbeitung nicht erforderlich ist:
            </span>
          </div>
          <div className="imTAJustify">
            <ul>
              <li>
                <span className="cf1">
                  <span className="fs16 ff1">
                    Die personenbezogenen Daten wurden für solche Zwecke erhoben
                    oder auf sonstige Weise verarbeitet, für welche sie nicht
                    mehr notwendig sind.
                  </span>
                  <br />
                </span>
              </li>
            </ul>
            <br />
            <ul>
              <li>
                <span className="cf1">
                  <span className="fs16 ff1">
                    Die betroffene Person widerruft ihre Einwilligung, auf die
                    sich die Verarbeitung gemäß Art. 6 Abs. 1 Buchstabe a DSGVO
                    oder Art. 9 Abs. 2 Buchstabe a DSGVO stützte, und es fehlt
                    an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
                  </span>
                  <br />
                </span>
              </li>
            </ul>
            <br />
            <ul>
              <li>
                <span className="cf1">
                  <span className="fs16 ff1">
                    Die betroffene Person legt gemäß Art. 21 Abs. 1 DSGVO
                    Widerspruch gegen die Verarbeitung ein, und es liegen keine
                    vorrangigen berechtigten Gründe für die Verarbeitung vor,
                    oder die betroffene Person legt gemäß Art. 21 Abs. 2 DSGVO
                    Widerspruch gegen die Verarbeitung ein.
                  </span>
                  <br />
                </span>
              </li>
            </ul>
            <br />
            <ul>
              <li>
                <span className="cf1">
                  <span className="fs16 ff1">
                    Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.
                  </span>
                  <br />
                </span>
              </li>
            </ul>
            <br />
            <ul>
              <li>
                <span className="cf1">
                  <span className="fs16 ff1">
                    Die Löschung der personenbezogenen Daten ist zur Erfüllung
                    einer rechtlichen Verpflichtung nach dem Unionsrecht oder
                    dem Recht der Mitgliedstaaten erforderlich, dem der
                    Verantwortliche unterliegt.
                  </span>
                  <br />
                </span>
              </li>
            </ul>
            <br />
            <ul>
              <li>
                <span className="cf1">
                  <span className="fs16 ff1">
                    Die personenbezogenen Daten wurden in Bezug auf angebotene
                    Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1
                    DSGVO erhoben.
                  </span>
                  <br />
                </span>
              </li>
            </ul>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Sofern einer der oben genannten Gründe zutrifft und eine
              betroffene Person die Löschung von personenbezogenen Daten, die
              bei uns gespeichert sind, veranlassen möchte, kann sie sich hierzu
              jederzeit an unseren Datenschutzbeauftragten oder einen anderen
              Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
              Unser Datenschutzbeauftragte oder ein anderer Mitarbeiter wird
              veranlassen, dass dem Löschverlangen unverzüglich nachgekommen
              wird.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Wurden die personenbezogenen Daten von uns öffentlich gemacht und
              ist unser Unternehmen als Verantwortlicher gemäß Art. 17 Abs. 1
              DSGVO zur Löschung der personenbezogenen Daten verpflichtet, so
              treffen wir unter Berücksichtigung der verfügbaren Technologie und
              der Implementierungskosten angemessene Maßnahmen, auch technischer
              Art, um andere für die Datenverarbeitung Verantwortliche, welche
              die veröffentlichten personenbezogenen Daten verarbeiten, darüber
              in Kenntnis zu setzen, dass die betroffene Person von diesen
              anderen für die Datenverarbeitung Verantwortlichen die Löschung
              sämtlicher Links zu diesen personenbezogenen Daten oder von Kopien
              oder Replikationen dieser personenbezogenen Daten verlangt hat,
              soweit die Verarbeitung nicht erforderlich ist. Unser
              Datenschutzbeauftragte oder ein anderer Mitarbeiter wird im
              Einzelfall das Notwendige veranlassen.
            </span>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              <br />
            </b>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              e) Recht auf Einschränkung der Verarbeitung
            </span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Jede von der Verarbeitung personenbezogener Daten betroffene
              Person hat das vom Europäischen Richtlinien- und Verordnungsgeber
              gewährte Recht, von dem Verantwortlichen die Einschränkung der
              Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen
              gegeben ist:
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <ul>
              <li>
                <span className="cf1">
                  <span className="fs16 ff1">
                    Die Richtigkeit der personenbezogenen Daten wird von der
                    betroffenen Person bestritten, und zwar für eine Dauer, die
                    es dem Verantwortlichen ermöglicht, die Richtigkeit der
                    personenbezogenen Daten zu überprüfen.
                  </span>
                  <br />
                </span>
              </li>
            </ul>
          </div>
          <div></div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <ul>
              <li>
                <span className="cf1">
                  <span className="fs16 ff1">
                    Die Verarbeitung ist unrechtmäßig, die betroffene Person
                    lehnt die Löschung der personenbezogenen Daten ab und
                    verlangt stattdessen die Einschränkung der Nutzung der
                    personenbezogenen Daten.
                  </span>
                  <br />
                </span>
              </li>
            </ul>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;&nbsp;</span>
          </div>
          <div className="imTAJustify">
            <ul>
              <li>
                <span className="cf1">
                  <span className="fs16 ff1">
                    Der Verantwortliche benötigt die personenbezogenen Daten für
                    die Zwecke der Verarbeitung nicht länger, die betroffene
                    Person benötigt sie jedoch zur Geltendmachung, Ausübung oder
                    Verteidigung von Rechtsansprüchen.
                  </span>
                  <br />
                </span>
              </li>
            </ul>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <ul>
              <li>
                <span className="cf1">
                  <span className="fs16 ff1">
                    Die betroffene Person hat Widerspruch gegen die Verarbeitung
                    gem. Art. 21 Abs. 1 DSGVO eingelegt und es steht noch nicht
                    fest, ob die berechtigten Gründe des Verantwortlichen
                    gegenüber denen der betroffenen Person überwiegen.
                  </span>
                  <br />
                </span>
              </li>
            </ul>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Sofern eine der oben genannten Voraussetzungen gegeben ist und
              eine betroffene Person die Einschränkung von personenbezogenen
              Daten, die bei uns gespeichert sind, verlangen möchte, kann sie
              sich hierzu jederzeit an unseren Datenschutzbeauftragten oder
              einen anderen Mitarbeiter des für die Verarbeitung
              Verantwortlichen wenden. Unser Datenschutzbeauftragter oder ein
              anderer Mitarbeiter wird die Einschränkung der Verarbeitung
              veranlassen.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">f) Recht auf Datenübertragbarkeit</span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Jede von der Verarbeitung personenbezogener Daten betroffene
              Person hat das vom Europäischen Richtlinien- und Verordnungsgeber
              gewährte Recht, die sie betreffenden personenbezogenen Daten,
              welche durch die betroffene Person einem Verantwortlichen
              bereitgestellt wurden, in einem strukturierten, gängigen und
              maschinenlesbaren Format zu erhalten. Sie hat außerdem das Recht,
              diese Daten einem anderen Verantwortlichen ohne Behinderung durch
              den Verantwortlichen, dem die personenbezogenen Daten
              bereitgestellt wurden, zu übermitteln, sofern die Verarbeitung auf
              der Einwilligung gemäß Art. 6 Abs. 1 Buchstabe a DSGVO oder Art. 9
              Abs. 2 Buchstabe a DSGVO oder auf einem Vertrag gemäß Art. 6 Abs.
              1 Buchstabe b DSGVO beruht und die Verarbeitung mithilfe
              automatisierter Verfahren erfolgt, sofern die Verarbeitung nicht
              für die Wahrnehmung einer Aufgabe erforderlich ist, die im
              öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt
              erfolgt, welche dem Verantwortlichen übertragen wurde.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Ferner hat die betroffene Person bei der Ausübung ihres Rechts auf
              Datenübertragbarkeit gemäß Art. 20 Abs. 1 DSGVO das Recht, zu
              erwirken, dass die personenbezogenen Daten direkt von einem
              Verantwortlichen an einen anderen Verantwortlichen übermittelt
              werden, soweit dies technisch machbar ist und sofern hiervon nicht
              die Rechte und Freiheiten anderer Personen beeinträchtigt werden.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Zur Geltendmachung des Rechts auf Datenübertragbarkeit kann sich
              die betroffene Person jederzeit an unseren bestellten
              Datenschutzbeauftragten oder einen anderen Mitarbeiter wenden.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">g) Recht auf Widerspruch</span>
          </div>
          <div>
            <span className="cf1 ff1">
              <b></b>
            </span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Jede von der Verarbeitung personenbezogener Daten betroffene
              Person hat das vom Europäischen Richtlinien- und Verordnungsgeber
              gewährte Recht, aus Gründen, die sich aus ihrer besonderen
              Situation ergeben, jederzeit gegen die Verarbeitung sie
              betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs.
              1 Buchstaben e oder f DSGVO erfolgt, Widerspruch einzulegen. Dies
              gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Wir verarbeiten die personenbezogenen Daten im Falle des
              Widerspruchs nicht mehr, es sei denn, wir können zwingende
              schutzwürdige Gründe für die Verarbeitung nachweisen, die den
              Interessen, Rechten und Freiheiten der betroffenen Person
              überwiegen, oder die Verarbeitung dient der Geltendmachung,
              Ausübung oder Verteidigung von Rechtsansprüchen.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Verarbeitet unser Unternehmen personenbezogene Daten, um
              Direktwerbung zu betreiben, so hat die betroffene Person das
              Recht, jederzeit Widerspruch gegen die Verarbeitung der
              personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen.
              Dies gilt auch für das Profiling, soweit es mit solcher
              Direktwerbung in Verbindung steht. Widerspricht die betroffene
              Person uns gegenüber der Verarbeitung für Zwecke der
              Direktwerbung, so werden wir die personenbezogenen Daten nicht
              mehr für diese Zwecke verarbeiten.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Zudem hat die betroffene Person das Recht, aus Gründen, die sich
              aus ihrer besonderen Situation ergeben, gegen die sie betreffende
              Verarbeitung personenbezogener Daten, die wir zu
              wissenschaftlichen oder historischen Forschungszwecken oder zu
              statistischen Zwecken gemäß Art. 89 Abs. 1 DSGVO erfolgen,
              Widerspruch einzulegen, es sei denn, eine solche Verarbeitung ist
              zur Erfüllung einer im öffentlichen Interesse liegenden Aufgabe
              erforderlich.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Zur Ausübung des Rechts auf Widerspruch kann sich die betroffene
              Person direkt an unseren Datenschutzbeauftragten oder einen
              anderen Mitarbeiter wenden. Der betroffenen Person steht es ferner
              frei, im Zusammenhang mit der Nutzung von Diensten der
              Informationsgesellschaft, ungeachtet der Richtlinie 2002/58/EG,
              ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben,
              bei denen technische Spezifikationen verwendet werden.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              h) Automatisierte Entscheidungen im Einzelfall einschließlich
              Profiling
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Jede von der Verarbeitung personenbezogener Daten betroffene
              Person hat das vom Europäischen Richtlinien- und Verordnungsgeber
              gewährte Recht, nicht einer ausschließlich auf einer
              automatisierten Verarbeitung — einschließlich Profiling —
              beruhenden Entscheidung unterworfen zu werden, die ihr gegenüber
              rechtliche Wirkung entfaltet oder sie in ähnlicher Weise erheblich
              beeinträchtigt, sofern die Entscheidung (1) nicht für den
              Abschluss oder die Erfüllung eines Vertrags zwischen der
              betroffenen Person und dem Verantwortlichen erforderlich ist, oder
              (2) aufgrund von Rechtsvorschriften der Union oder der
              Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig
              ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung
              der Rechte und Freiheiten sowie der berechtigten Interessen der
              betroffenen Person enthalten oder (3) mit ausdrücklicher
              Einwilligung der betroffenen Person erfolgt.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Ist die Entscheidung (1) für den Abschluss oder die Erfüllung
              eines Vertrags zwischen der betroffenen Person und dem
              Verantwortlichen erforderlich oder (2) erfolgt sie mit
              ausdrücklicher Einwilligung der betroffenen Person, treffen wir
              angemessene Maßnahmen, um die Rechte und Freiheiten sowie die
              berechtigten Interessen der betroffenen Person zu wahren, wozu
              mindestens das Recht auf Erwirkung des Eingreifens einer Person
              seitens des Verantwortlichen, auf Darlegung des eigenen
              Standpunkts und auf Anfechtung der Entscheidung gehört.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Möchte die betroffene Person Rechte mit Bezug auf automatisierte
              Entscheidungen geltend machen, kann sie sich hierzu jederzeit an
              unseren Datenschutzbeauftragten oder einen anderen Mitarbeiter des
              für die Verarbeitung Verantwortlichen wenden.
            </span>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              <br />
            </b>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              i) Recht auf Widerruf einer datenschutzrechtlichen Einwilligung
            </span>
          </div>
          <div>
            <span className="cf1 ff1">
              <b></b>
            </span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Jede von der Verarbeitung personenbezogener Daten betroffene
              Person hat das vom Europäischen Richtlinien- und Verordnungsgeber
              gewährte Recht, eine Einwilligung zur Verarbeitung
              personenbezogener Daten jederzeit zu widerrufen.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Möchte die betroffene Person ihr Recht auf Widerruf einer
              Einwilligung geltend machen, kann sie sich hierzu jederzeit an
              unseren Datenschutzbeauftragten oder einen anderen Mitarbeiter des
              für die Verarbeitung Verantwortlichen wenden.
            </span>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              <br />
            </b>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              7. Datenschutz bei Bewerbungen und im Bewerbungsverfahren
            </b>
          </div>
          <div>
            <span className="cf1 ff1">
              <b></b>
            </span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Der für die Verarbeitung Verantwortliche erhebt und verarbeitet
              die personenbezogenen Daten von Bewerbern zum Zwecke der
              Abwicklung des Bewerbungsverfahrens. Die Verarbeitung kann auch
              auf elektronischem Wege erfolgen. Dies ist insbesondere dann der
              Fall, wenn ein Bewerber entsprechende Bewerbungsunterlagen auf dem
              elektronischen Wege, beispielsweise per E-Mail oder über ein auf
              der Internetseite befindliches Webformular, an den für die
              Verarbeitung Verantwortlichen übermittelt. Schließt der für die
              Verarbeitung Verantwortliche einen Anstellungsvertrag mit einem
              Bewerber, werden die übermittelten Daten zum Zwecke der Abwicklung
              des Beschäftigungsverhältnisses unter Beachtung der gesetzlichen
              Vorschriften gespeichert. Wird von dem für die Verarbeitung
              Verantwortlichen kein Anstellungsvertrag mit dem Bewerber
              geschlossen, so werden die Bewerbungsunterlagen zwei Monate nach
              Bekanntgabe der Absageentscheidung automatisch gelöscht, sofern
              einer Löschung keine sonstigen berechtigten Interessen des für die
              Verarbeitung Verantwortlichen entgegenstehen. Sonstiges
              berechtigtes Interesse in diesem Sinne ist beispielsweise eine
              Beweispflicht in einem Verfahren nach dem Allgemeinen
              Gleichbehandlungsgesetz (AGG). &nbsp;
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              8. Datenschutzbestimmungen zu Einsatz und Verwendung von Facebook
            </b>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Der für die Verarbeitung Verantwortliche hat auf dieser
              Internetseite Komponenten des Unternehmens Facebook integriert.
              Facebook ist ein soziales Netzwerk.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Ein soziales Netzwerk ist ein im Internet betriebener sozialer
              Treffpunkt, eine Online-Gemeinschaft, die es den Nutzern in der
              Regel ermöglicht, untereinander zu kommunizieren und im virtuellen
              Raum zu interagieren. Ein soziales Netzwerk kann als Plattform zum
              Austausch von Meinungen und Erfahrungen dienen oder ermöglicht es
              der Internetgemeinschaft, persönliche oder unternehmensbezogene
              Informationen bereitzustellen. Facebook ermöglicht den Nutzern des
              sozialen Netzwerkes unter anderem die Erstellung von privaten
              Profilen, den Upload von Fotos und eine Vernetzung über
              Freundschaftsanfragen.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Betreibergesellschaft von Facebook ist die Facebook, Inc., 1
              Hacker Way, Menlo Park, CA 94025, USA. Für die Verarbeitung
              personenbezogener Daten Verantwortlicher ist, wenn eine betroffene
              Person außerhalb der USA oder Kanada lebt, die Facebook Ireland
              Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2,
              Ireland.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Durch jeden Aufruf einer der Einzelseiten dieser Internetseite,
              die durch den für die Verarbeitung Verantwortlichen betrieben wird
              und auf welcher eine Facebook-Komponente (Facebook-Plug-In)
              integriert wurde, wird der Internetbrowser auf dem
              informationstechnologischen System der betroffenen Person
              automatisch durch die jeweilige Facebook-Komponente veranlasst,
              eine Darstellung der entsprechenden Facebook-Komponente von
              Facebook herunterzuladen. Eine Gesamtübersicht über alle
              Facebook-Plug-Ins kann unter
              https://developers.facebook.com/docs/plugins/?locale=de_DE
              abgerufen werden. Im Rahmen dieses technischen Verfahrens erhält
              Facebook Kenntnis darüber, welche konkrete Unterseite unserer
              Internetseite durch die betroffene Person besucht wird.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Sofern die betroffene Person gleichzeitig bei Facebook eingeloggt
              ist, erkennt Facebook mit jedem Aufruf unserer Internetseite durch
              die betroffene Person und während der gesamten Dauer des
              jeweiligen Aufenthaltes auf unserer Internetseite, welche konkrete
              Unterseite unserer Internetseite die betroffene Person besucht.
              Diese Informationen werden durch die Facebook-Komponente gesammelt
              und durch Facebook dem jeweiligen Facebook-Account der betroffenen
              Person zugeordnet. Betätigt die betroffene Person einen der auf
              unserer Internetseite integrierten Facebook-Buttons,
              beispielsweise den „Gefällt mir“-Button, oder gibt die betroffene
              Person einen Kommentar ab, ordnet Facebook diese Information dem
              persönlichen Facebook-Benutzerkonto der betroffenen Person zu und
              speichert diese personenbezogenen Daten.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Facebook erhält über die Facebook-Komponente immer dann eine
              Information darüber, dass die betroffene Person unsere
              Internetseite besucht hat, wenn die betroffene Person zum
              Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei
              Facebook eingeloggt ist; dies findet unabhängig davon statt, ob
              die betroffene Person die Facebook-Komponente anklickt oder nicht.
              Ist eine derartige Übermittlung dieser Informationen an Facebook
              von der betroffenen Person nicht gewollt, kann diese die
              Übermittlung dadurch verhindern, dass sie sich vor einem Aufruf
              unserer Internetseite aus ihrem Facebook-Account ausloggt.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Die von Facebook veröffentlichte Datenrichtlinie, die unter
              https://de-de.facebook.com/about/privacy/ abrufbar ist, gibt
              Aufschluss über die Erhebung, Verarbeitung und Nutzung
              personenbezogener Daten durch Facebook. Ferner wird dort
              erläutert, welche Einstellungsmöglichkeiten Facebook zum Schutz
              der Privatsphäre der betroffenen Person bietet. Zudem sind
              unterschiedliche Applikationen erhältlich, die es ermöglichen,
              eine Datenübermittlung an Facebook zu unterdrücken. Solche
              Applikationen können durch die betroffene Person genutzt werden,
              um eine Datenübermittlung an Facebook zu unterdrücken.
              &nbsp;&nbsp;
            </span>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              <br />
            </b>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              <b>9.</b>
              <b> </b>
              <b>Datenschutzbestimmungen zu Einsatz und Verwendung von </b>
              <b>Google Analytics (mit Anonymisierungsfunktion)</b>
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div></div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Der für die Verarbeitung Verantwortliche hat auf dieser
              Internetseite die Komponente Google Analytics (mit
              Anonymisierungsfunktion) integriert. Google Analytics ist ein
              Web-Analyse-Dienst. Web-Analyse ist die Erhebung, Sammlung und
              Auswertung von Daten über das Verhalten von Besuchern von
              Internetseiten. Ein Web-Analyse-Dienst erfasst unter anderem Daten
              darüber, von welcher Internetseite eine betroffene Person auf eine
              Internetseite gekommen ist (sogenannte Referrer), auf welche
              Unterseiten der Internetseite zugegriffen oder wie oft und für
              welche Verweildauer eine Unterseite betrachtet wurde. Eine
              Web-Analyse wird überwiegend zur Optimierung einer Internetseite
              und zur Kosten-Nutzen-Analyse von Internetwerbung eingesetzt.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Betreibergesellschaft der Google-Analytics-Komponente ist die
              Google Inc., 1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351,
              USA.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Der für die Verarbeitung Verantwortliche verwendet für die
              Web-Analyse über Google Analytics den Zusatz "_gat._anonymizeIp".
              Mittels dieses Zusatzes wird die IP-Adresse des
              Internetanschlusses der betroffenen Person von Google gekürzt und
              anonymisiert, wenn der Zugriff auf unsere Internetseiten aus einem
              Mitgliedstaat der Europäischen Union oder aus einem anderen
              Vertragsstaat des Abkommens über den Europäischen Wirtschaftsraum
              erfolgt.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Der Zweck der Google-Analytics-Komponente ist die Analyse der
              Besucherströme auf unserer Internetseite. Google nutzt die
              gewonnenen Daten und Informationen unter anderem dazu, die Nutzung
              unserer Internetseite auszuwerten, um für uns Online-Reports,
              welche die Aktivitäten auf unseren Internetseiten aufzeigen,
              zusammenzustellen, und um weitere mit der Nutzung unserer
              Internetseite in Verbindung stehende Dienstleistungen zu
              erbringen. Google Analytics setzt ein Cookie auf dem
              informationstechnologischen System der betroffenen Person. Was
              Cookies sind, wurde oben bereits erläutert. Mit Setzung des
              Cookies wird Google eine Analyse der Benutzung unserer
              Internetseite ermöglicht. Durch jeden Aufruf einer der
              Einzelseiten dieser Internetseite, die durch den für die
              Verarbeitung Verantwortlichen betrieben wird und auf welcher eine
              Google-Analytics-Komponente integriert wurde, wird der
              Internetbrowser auf dem informationstechnologischen System der
              betroffenen Person automatisch durch die jeweilige
              Google-Analytics-Komponente veranlasst, Daten zum Zwecke der
              Online-Analyse an Google zu übermitteln. Im Rahmen dieses
              technischen Verfahrens erhält Google Kenntnis über
              personenbezogene Daten, wie der IP-Adresse der betroffenen Person,
              die Google unter anderem dazu dienen, die Herkunft der Besucher
              und Klicks nachzuvollziehen und in der Folge
              Provisionsabrechnungen zu ermöglichen.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Mittels des Cookies werden personenbezogene Informationen,
              beispielsweise die Zugriffszeit, der Ort, von welchem ein Zugriff
              ausging und die Häufigkeit der Besuche unserer Internetseite durch
              die betroffene Person, gespeichert. Bei jedem Besuch unserer
              Internetseiten werden diese personenbezogenen Daten,
              einschließlich der IP-Adresse des von der betroffenen Person
              genutzten Internetanschlusses, an Google in den Vereinigten
              Staaten von Amerika übertragen. Diese personenbezogenen Daten
              werden durch Google in den Vereinigten Staaten von Amerika
              gespeichert. Google gibt diese über das technische Verfahren
              erhobenen personenbezogenen Daten unter Umständen an Dritte
              weiter. Die betroffene Person kann die Setzung von Cookies durch
              unsere Internetseite, wie oben bereits dargestellt, jederzeit
              mittels einer entsprechenden Einstellung des genutzten
              Internetbrowsers verhindern und damit der Setzung von Cookies
              dauerhaft widersprechen. Eine solche Einstellung des genutzten
              Internetbrowsers würde auch verhindern, dass Google ein Cookie auf
              dem informationstechnologischen System der betroffenen Person
              setzt. Zudem kann ein von Google Analytics bereits gesetzter
              Cookie jederzeit über den Internetbrowser oder andere
              Softwareprogramme gelöscht werden. Ferner besteht für die
              betroffene Person die Möglichkeit, einer Erfassung der durch
              Google Analytics erzeugten, auf eine Nutzung dieser Internetseite
              bezogenen Daten sowie der Verarbeitung dieser Daten durch Google
              zu widersprechen und eine solche zu verhindern. Hierzu muss die
              betroffene Person ein Browser-Add-On unter dem Link
              https://tools.google.com/dlpage/gaoptout herunterladen und
              installieren. Dieses Browser-Add-On teilt Google Analytics über
              JavaScript mit, dass keine Daten und Informationen zu den Besuchen
              von Internetseiten an Google Analytics übermittelt werden dürfen.
              Die Installation des Browser-Add-Ons wird von Google als
              Widerspruch gewertet. Wird das informationstechnologische System
              der betroffenen Person zu einem späteren Zeitpunkt gelöscht,
              formatiert oder neu installiert, muss durch die betroffene Person
              eine erneute Installation des Browser-Add-Ons erfolgen, um Google
              Analytics zu deaktivieren. Sofern das Browser-Add-On durch die
              betroffene Person oder einer anderen Person, die ihrem
              Machtbereich zuzurechnen ist, deinstalliert oder deaktiviert wird,
              besteht die Möglichkeit der Neuinstallation oder der erneuten
              Aktivierung des Browser-Add-Ons.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Weitere Informationen und die geltenden Datenschutzbestimmungen
              von Google können unter
              https://www.google.de/intl/de/policies/privacy/ &nbsp;und unter
              http://www.google.com/analytics/terms/de.html abgerufen werden.
              Google Analytics wird unter diesem Link
              https://www.google.com/intl/de_de/analytics/ genauer erläutert.
              &nbsp;&nbsp;
            </span>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              <br />
            </b>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              <b>10. </b>
              <b>Datenschutzbestimmungen zu Einsatz und Verwendung von </b>
              <b>Google+</b>
            </span>
          </div>
          <div>
            <span className="cf1 ff1">
              <b></b>
            </span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Der für die Verarbeitung Verantwortliche hat auf dieser
              Internetseite als Komponente die Google+ Schaltfläche integriert.
              Google+ ist ein sogenanntes soziales Netzwerk. Ein soziales
              Netzwerk ist ein im Internet betriebener sozialer Treffpunkt, eine
              Online-Gemeinschaft, die es den Nutzern in der Regel ermöglicht,
              untereinander zu kommunizieren und im virtuellen Raum zu
              interagieren. Ein soziales Netzwerk kann als Plattform zum
              Austausch von Meinungen und Erfahrungen dienen oder ermöglicht es
              der Internetgemeinschaft, persönliche oder unternehmensbezogene
              Informationen bereitzustellen. Google+ ermöglicht den Nutzern des
              sozialen Netzwerkes unter anderem die Erstellung von privaten
              Profilen, den Upload von Fotos und eine Vernetzung über
              Freundschaftsanfragen.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Betreibergesellschaft von Google+ ist die Google Inc., 1600
              Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Durch jeden Aufruf einer der Einzelseiten dieser Internetseite,
              die durch den für die Verarbeitung Verantwortlichen betrieben wird
              und auf welcher eine Google+ Schaltfläche integriert wurde, wird
              der Internetbrowser auf dem informationstechnologischen System der
              betroffenen Person automatisch durch die jeweilige Google+
              Schaltfläche veranlasst, eine Darstellung der entsprechenden
              Google+ Schaltfläche von Google herunterzuladen. Im Rahmen dieses
              technischen Verfahrens erhält Google Kenntnis darüber, welche
              konkrete Unterseite unserer Internetseite durch die betroffene
              Person besucht wird. Genauere Informationen zu Google+ sind unter
              https://developers.google.com/+/ abrufbar.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Sofern die betroffene Person gleichzeitig bei Google+ eingeloggt
              ist, erkennt Google mit jedem Aufruf unserer Internetseite durch
              die betroffene Person und während der gesamten Dauer des
              jeweiligen Aufenthaltes auf unserer Internetseite, welche konkrete
              Unterseite unserer Internetseite die betroffene Person besucht.
              Diese Informationen werden durch die Google+ Schaltfläche
              gesammelt und durch Google dem jeweiligen Google+-Account der
              betroffenen Person zugeordnet.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Betätigt die betroffene Person einen der auf unserer Internetseite
              integrierten Google+-Buttons und gibt damit eine Google+1
              Empfehlung ab, ordnet Google diese Information dem persönlichen
              Google+-Benutzerkonto der betroffenen Person zu und speichert
              diese personenbezogenen Daten. Google speichert die
              Google+1-Empfehlung der betroffenen Person und macht diese in
              Übereinstimmung mit den von der betroffenen Person diesbezüglich
              akzeptierten Bedingungen öffentlich zugänglich. Eine von der
              betroffenen Person auf dieser Internetseite abgegebene
              Google+1-Empfehlung wird in der Folge zusammen mit anderen
              personenbezogenen Daten, wie dem Namen des von der betroffenen
              Person genutzten Google+1-Accounts und dem in diesem hinterlegten
              Foto in anderen Google-Diensten, beispielsweise den
              Suchmaschinenergebnissen der Google-Suchmaschine, dem Google-Konto
              der betroffenen Person oder an sonstigen Stellen, beispielsweise
              auf Internetseiten oder im Zusammenhang mit Werbeanzeigen,
              gespeichert und verarbeitet. Ferner ist Google in der Lage, den
              Besuch auf dieser Internetseite mit anderen bei Google
              gespeicherten personenbezogenen Daten zu verknüpfen. Google
              zeichnet diese personenbezogenen Informationen ferner mit dem
              Zweck auf, die unterschiedlichen Dienste von Google zu verbessern
              oder zu optimieren.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Google erhält über die Google+-Schaltfläche immer dann eine
              Information darüber, dass die betroffene Person unsere
              Internetseite besucht hat, wenn die betroffene Person zum
              Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei
              Google+ eingeloggt ist; dies findet unabhängig davon statt, ob die
              betroffene Person die Google+-Schaltfläche anklickt oder nicht.
              Ist eine Übermittlung personenbezogener Daten an Google von der
              betroffenen Person nicht gewollt, kann diese eine solche
              Übermittlung dadurch verhindern, dass sie sich vor einem Aufruf
              unserer Internetseite aus ihrem Google+-Account ausloggt. Weitere
              Informationen und die geltenden Datenschutzbestimmungen von Google
              können unter https://www.google.de/intl/de/policies/privacy/
              abgerufen werden. Weitere Hinweise von Google zur
              Google+1-Schaltfläche können unter
              https://developers.google.com/+/web/buttons-policy abgerufen
              werden.
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              11. Datenschutzbestimmungen zu Einsatz und Verwendung von
              Google-AdWords
            </b>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Der für die Verarbeitung Verantwortliche hat auf dieser
              Internetseite Google AdWords integriert. Google AdWords ist ein
              Dienst zur Internetwerbung, der es Werbetreibenden gestattet,
              sowohl Anzeigen in den Suchmaschinenergebnissen von Google als
              auch im Google-Werbenetzwerk zu schalten. Google AdWords
              ermöglicht es einem Werbetreibenden, vorab bestimmte
              Schlüsselwörter festzulegen, mittels derer eine Anzeige in den
              Suchmaschinenergebnissen von Google ausschließlich dann angezeigt
              wird, wenn der Nutzer mit der Suchmaschine ein
              schlüsselwortrelevantes Suchergebnis abruft. Im
              Google-Werbenetzwerk werden die Anzeigen mittels eines
              automatischen Algorithmus und unter Beachtung der zuvor
              festgelegten Schlüsselwörter auf themenrelevanten Internetseiten
              verteilt.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Betreibergesellschaft der Dienste von Google AdWords ist die
              Google Inc., 1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351,
              USA.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Der Zweck von Google AdWords ist die Bewerbung unserer
              Internetseite durch die Einblendung von interessenrelevanter
              Werbung auf den Internetseiten von Drittunternehmen und in den
              Suchmaschinenergebnissen der Suchmaschine Google und eine
              Einblendung von Fremdwerbung auf unserer Internetseite.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Gelangt eine betroffene Person über eine Google-Anzeige auf unsere
              Internetseite, wird auf dem informationstechnologischen System der
              betroffenen Person durch Google ein sogenannter Conversion-Cookie
              abgelegt. Was Cookies sind, wurde oben bereits erläutert. Ein
              Conversion-Cookie verliert nach dreißig Tagen seine Gültigkeit und
              dient nicht zur Identifikation der betroffenen Person. Über den
              Conversion-Cookie wird, sofern das Cookie noch nicht abgelaufen
              ist, nachvollzogen, ob bestimmte Unterseiten, beispielsweise der
              Warenkorb von einem Online-Shop-System, auf unserer Internetseite
              aufgerufen wurden. Durch den Conversion-Cookie können sowohl wir
              als auch Google nachvollziehen, ob eine betroffene Person, die
              über eine AdWords-Anzeige auf unsere Internetseite gelangt ist,
              einen Umsatz generierte, also einen Warenkauf vollzogen oder
              abgebrochen hat.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Die durch die Nutzung des Conversion-Cookies erhobenen Daten und
              Informationen werden von Google verwendet, um Besuchsstatistiken
              für unsere Internetseite zu erstellen. Diese Besuchsstatistiken
              werden durch uns wiederum genutzt, um die Gesamtanzahl der Nutzer
              zu ermitteln, welche über AdWords-Anzeigen an uns vermittelt
              wurden, also um den Erfolg oder Misserfolg der jeweiligen
              AdWords-Anzeige zu ermitteln und um unsere AdWords-Anzeigen für
              die Zukunft zu optimieren. Weder unser Unternehmen noch andere
              Werbekunden von Google-AdWords erhalten Informationen von Google,
              mittels derer die betroffene Person identifiziert werden könnte.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Mittels des Conversion-Cookies werden personenbezogene
              Informationen, beispielsweise die durch die betroffene Person
              besuchten Internetseiten, gespeichert. Bei jedem Besuch unserer
              Internetseiten werden demnach personenbezogene Daten,
              einschließlich der IP-Adresse des von der betroffenen Person
              genutzten Internetanschlusses, an Google in den Vereinigten
              Staaten von Amerika übertragen. Diese personenbezogenen Daten
              werden durch Google in den Vereinigten Staaten von Amerika
              gespeichert. Google gibt diese über das technische Verfahren
              erhobenen personenbezogenen Daten unter Umständen an Dritte
              weiter.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Die betroffene Person kann die Setzung von Cookies durch unsere
              Internetseite, wie oben bereits dargestellt, jederzeit mittels
              einer entsprechenden Einstellung des genutzten Internetbrowsers
              verhindern und damit der Setzung von Cookies dauerhaft
              widersprechen. Eine solche Einstellung des genutzten
              Internetbrowsers würde auch verhindern, dass Google einen
              Conversion-Cookie auf dem informationstechnologischen System der
              betroffenen Person setzt. Zudem kann ein von Google AdWords
              bereits gesetzter Cookie jederzeit über den Internetbrowser oder
              andere Softwareprogramme gelöscht werden.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Ferner besteht für die betroffene Person die Möglichkeit, der
              interessenbezogenen Werbung durch Google zu widersprechen. Hierzu
              muss die betroffene Person von jedem der von ihr genutzten
              Internetbrowser aus den Link www.google.de/settings/ads aufrufen
              und dort die gewünschten Einstellungen vornehmen.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Weitere Informationen und die geltenden Datenschutzbestimmungen
              von Google können unter
              https://www.google.de/intl/de/policies/privacy/ abgerufen werden.
            </span>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              <br />
            </b>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              12. Datenschutzbestimmungen zu Einsatz und Verwendung von YouTube
            </b>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Der für die Verarbeitung Verantwortliche hat auf dieser
              Internetseite Komponenten von YouTube integriert. YouTube ist ein
              Internet-Videoportal, dass Video-Publishern das kostenlose
              Einstellen von Videoclips und anderen Nutzern die ebenfalls
              kostenfreie Betrachtung, Bewertung und Kommentierung dieser
              ermöglicht. YouTube gestattet die Publikation aller Arten von
              Videos, weshalb sowohl komplette Film- und Fernsehsendungen, aber
              auch Musikvideos, Trailer oder von Nutzern selbst angefertigte
              Videos über das Internetportal abrufbar sind.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Betreibergesellschaft von YouTube ist die YouTube, LLC, 901 Cherry
              Ave., San Bruno, CA 94066, USA. Die YouTube, LLC ist einer
              Tochtergesellschaft der Google Inc., 1600 Amphitheatre Pkwy,
              Mountain View, CA 94043-1351, USA.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Durch jeden Aufruf einer der Einzelseiten dieser Internetseite,
              die durch den für die Verarbeitung Verantwortlichen betrieben wird
              und auf welcher eine YouTube-Komponente (YouTube-Video) integriert
              wurde, wird der Internetbrowser auf dem
              informationstechnologischen System der betroffenen Person
              automatisch durch die jeweilige YouTube-Komponente veranlasst,
              eine Darstellung der entsprechenden YouTube-Komponente von YouTube
              herunterzuladen. Weitere Informationen zu YouTube können unter
              https://www.youtube.com/yt/about/de/ abgerufen werden.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Im Rahmen dieses technischen Verfahrens erhalten YouTube und
              Google Kenntnis darüber, welche konkrete Unterseite unserer
              Internetseite durch die betroffene Person besucht wird.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Sofern die betroffene Person gleichzeitig bei YouTube eingeloggt
              ist, erkennt YouTube mit dem Aufruf einer Unterseite, die ein
              YouTube-Video enthält, welche konkrete Unterseite unserer
              Internetseite die betroffene Person besucht. Diese Informationen
              werden durch YouTube und Google gesammelt und dem jeweiligen
              YouTube-Account der betroffenen Person zugeordnet.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              YouTube und Google erhalten über die YouTube-Komponente immer dann
              eine Information darüber, dass die betroffene Person unsere
              Internetseite besucht hat, wenn die betroffene Person zum
              Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei
              YouTube eingeloggt ist; dies findet unabhängig davon statt, ob die
              betroffene Person ein YouTube-Video anklickt oder nicht. Ist eine
              derartige Übermittlung dieser Informationen an YouTube und Google
              von der betroffenen Person nicht gewollt, kann diese die
              Übermittlung dadurch verhindern, dass sie sich vor einem Aufruf
              unserer Internetseite aus ihrem YouTube-Account ausloggt.
            </span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Die von YouTube veröffentlichten Datenschutzbestimmungen, die
              unter https://www.google.de/intl/de/policies/privacy/ abrufbar
              sind, geben Aufschluss über die Erhebung, Verarbeitung und Nutzung
              personenbezogener Daten durch YouTube und Google. &nbsp;&nbsp;
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">13. Rechtsgrundlage der Verarbeitung</b>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Art. 6 I lit. a DSGVO dient unserem Unternehmen als
              Rechtsgrundlage für Verarbeitungsvorgänge, bei denen wir eine
              Einwilligung für einen bestimmten Verarbeitungszweck einholen. Ist
              die Verarbeitung personenbezogener Daten zur Erfüllung eines
              Vertrags, dessen Vertragspartei die betroffene Person ist,
              erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen
              der Fall ist, die für eine Lieferung von Waren oder die Erbringung
              einer sonstigen Leistung oder Gegenleistung notwendig sind, so
              beruht die Verarbeitung auf Art. 6 I lit. b DSGVO. Gleiches gilt
              für solche Verarbeitungsvorgänge die zur Durchführung
              vorvertraglicher Maßnahmen erforderlich sind, etwa in Fällen von
              Anfragen zur unseren Produkten oder Leistungen. Unterliegt unser
              Unternehmen einer rechtlichen Verpflichtung durch welche eine
              Verarbeitung von personenbezogenen Daten erforderlich wird, wie
              beispielsweise zur Erfüllung steuerlicher Pflichten, so basiert
              die Verarbeitung auf Art. 6 I lit. c DSGVO. In seltenen Fällen
              könnte die Verarbeitung von personenbezogenen Daten erforderlich
              werden, um lebenswichtige Interessen der betroffenen Person oder
              einer anderen natürlichen Person zu schützen. Dies wäre
              beispielsweise der Fall, wenn ein Besucher in unserem Betrieb
              verletzt werden würde und daraufhin sein Name, sein Alter, seine
              Krankenkassendaten oder sonstige lebenswichtige Informationen an
              einen Arzt, ein Krankenhaus oder sonstige Dritte weitergegeben
              werden müssten. Dann würde die Verarbeitung auf Art. 6 I lit. d
              DSGVO beruhen. Letztlich könnten Verarbeitungsvorgänge auf Art. 6
              I lit. f DSGVO beruhen. Auf dieser Rechtsgrundlage basieren
              Verarbeitungsvorgänge, die von keiner der vorgenannten
              Rechtsgrundlagen erfasst werden, wenn die Verarbeitung zur Wahrung
              eines berechtigten Interesses unseres Unternehmens oder eines
              Dritten erforderlich ist, sofern die Interessen, Grundrechte und
              Grundfreiheiten des Betroffenen nicht überwiegen. Solche
              Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet,
              weil sie durch den Europäischen Gesetzgeber besonders erwähnt
              wurden. Er vertrat insoweit die Auffassung, dass ein berechtigtes
              Interesse anzunehmen sein könnte, wenn die betroffene Person ein
              Kunde des Verantwortlichen ist (Erwägungsgrund 47 Satz 2 DSGVO).
              &nbsp;&nbsp;
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              14. Berechtigte Interessen an der Verarbeitung, die von dem
              Verantwortlichen oder einem Dritten verfolgt werden
            </b>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              <br />
            </b>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I
              lit. f DSGVO ist unser berechtigtes Interesse die Durchführung
              unserer Geschäftstätigkeit zugunsten des Wohlergehens all unserer
              Mitarbeiter und unserer Anteilseigner. &nbsp;&nbsp;
            </span>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              <br />
            </b>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              15. Dauer, für die die personenbezogenen Daten gespeichert werden
            </b>
          </div>
          <div>
            <span className="cf1 ff1">
              <b></b>
            </span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Das Kriterium für die Dauer der Speicherung von personenbezogenen
              Daten ist die jeweilige gesetzliche Aufbewahrungsfrist. Nach
              Ablauf der Frist werden die entsprechenden Daten routinemäßig
              gelöscht, sofern sie nicht mehr zur Vertragserfüllung oder
              Vertragsanbahnung erforderlich sind. &nbsp;&nbsp;
            </span>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              16. Gesetzliche oder vertragliche Vorschriften zur Bereitstellung
              der personenbezogenen Daten; Erforderlichkeit für den
              Vertragsabschluss; Verpflichtung der betroffenen Person, die
            </b>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              personenbezogenen Daten bereitzustellen; mögliche Folgen der
              Nichtbereitstellung
            </b>
          </div>
          <div>
            <span className="cf1 ff1">
              <b></b>
            </span>
          </div>
          <div>
            <span className="cf1 ff1"> &nbsp;</span>
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Wir klären Sie darüber auf, dass die Bereitstellung
              personenbezogener Daten zum Teil gesetzlich vorgeschrieben ist
              (z.B. Steuervorschriften) oder sich auch aus vertraglichen
              Regelungen (z.B. Angaben zum Vertragspartner) ergeben kann.
              Mitunter kann es zu einem Vertragsschluss erforderlich sein, dass
              eine betroffene Person uns personenbezogene Daten zur Verfügung
              stellt, die in der Folge durch uns verarbeitet werden müssen. Die
              betroffene Person ist beispielsweise verpflichtet uns
              personenbezogene Daten bereitzustellen, wenn unser Unternehmen mit
              ihr einen Vertrag abschließt. Eine Nichtbereitstellung der
              personenbezogenen Daten hätte zur Folge, dass der Vertrag mit dem
              Betroffenen nicht geschlossen werden könnte. Vor einer
              Bereitstellung personenbezogener Daten durch den Betroffenen muss
              sich der Betroffene an unseren Datenschutzbeauftragten wenden.
              Unser Datenschutzbeauftragter klärt den Betroffenen
              einzelfallbezogen darüber auf, ob die Bereitstellung der
              personenbezogenen Daten gesetzlich oder vertraglich vorgeschrieben
              oder für den Vertragsabschluss erforderlich ist, ob eine
              Verpflichtung besteht, die personenbezogenen Daten
              bereitzustellen, und welche Folgen die Nichtbereitstellung der
              personenbezogenen Daten hätte. &nbsp;&nbsp;
            </span>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              <br />
            </b>
          </div>
          <div className="imTAJustify">
            <b className="cf1 ff1">
              17. Bestehen einer automatisierten Entscheidungsfindung
            </b>
          </div>
          <div className="imTAJustify">
            <br />
          </div>
          <div className="imTAJustify">
            <span className="cf1 ff1">
              Als verantwortungsbewusstes Unternehmen verzichten wir auf eine
              automatische Entscheidungsfindung oder ein Profiling.
              <br />
            </span>
          </div>
          <div>
            <span className="cf1 ff1">Weiterführende Informationen zur DSGVO</span>
          </div>
          <div>
            <br />
          </div>
          <div>
            <span className="cf1 ff1">
              https://www.bmwi.de/Redaktion/DE/Artikel/Digitale-Welt/europaeische-datenschutzgrundverordnung.html
            </span>
          </div>
          <div>
            <br />
          </div>
          <div>
            <span className="cf1 ff1">https://dsgvo-gesetz.de/</span>
          </div>
          <div>
            <br />
          </div>
          <div>
            <span className="cf1 ff1">
              https://www.datenschutz-grundverordnung.eu/
            </span>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PrivacyPolicy;
